import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDosWIOAfEBnoNJP1MuqM6TCNOEPUApaYI",
  authDomain: "fitness-app-239dc.firebaseapp.com",
  projectId: "fitness-app-239dc",
  storageBucket: "fitness-app-239dc.appspot.com",
  messagingSenderId: "93991687188",
  appId: "1:93991687188:web:bacd028f8c47ca6ae24301"
};

export default firebaseConfig;